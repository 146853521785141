import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { MsalProvider } from "@azure/msal-react";
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from './authConfig';
import { handleLogin } from './AuthenticationHelper';

const pca = new PublicClientApplication(msalConfig);
pca.initialize().then(() => {
  handleLogin(pca);
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <MsalProvider instance={pca}>
      <App />
    </ MsalProvider>
  </React.StrictMode>
);